<template>
  <div class="course" ref="course" v-if="course">
    <div class="course__inner container container--big">
      <div class="course__inner-left">
        <div class="course__inner-video">
          <transition name="fade" mode="out-in">
            <video-loader v-if="!videoLoading && videoShow" />

            <course-video-player
              v-else-if="activeLesson && videoShow"
              :videoPoster="imageLink"
              :videoID="activeLesson ? activeLesson.assetId : ''"
              :lessonID="activeLesson ? activeLesson.id : ''"
              :courseID="course.id"
              :captions="activeCaptions"
              :sources="activeSources"
              mode="free"
            />

            <div v-if="!videoShow">
              <img :src="imageLink" class="course__inner-image" />
              <button
                v-if="hasFoundFree"
                class="play-icon"
                aria-label="Play preview"
                title="Play preview"
                @click="videoShow = !videoShow"
              >
                <img
                  alt=""
                  width="80px"
                  height="auto"
                  src="/assets/icons/play-button-big.svg"
                />
              </button>
            </div>
          </transition>
        </div>
        <div class="course-content">
          <div class="course-content__inner">
            <section
              class="course-content__section course-content__section--mobile"
            >
              <course-info :course="course" mode="mobile" />
            </section>

            <section class="course-content__section">
              <base-sub-heading>
                {{ $t("course.about") }}
              </base-sub-heading>

              <base-heading class="course-content__section-heading">
                {{ $t("course.description") }}
              </base-heading>

              <p
                class="course-content__section-no-data"
                v-if="course.description.length === 0"
              >
                {{ $t("no data") }}
              </p>

              <p class="course-content__description" v-else>
                {{ course.description }}
              </p>
            </section>

            <section class="course-content__section">
              <base-heading class="course-content__section-heading">
                {{ $t("course.willLearn") }}
              </base-heading>

              <p
                class="course-content__section-no-data"
                v-if="willLearn.length === 0"
              >
                {{ $t("no data") }}
              </p>

              <ul class="course-content__list" v-else>
                <li
                  class="course-content__list-item"
                  v-for="item in willLearn"
                  :key="item"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="29.535"
                    height="29.535"
                    viewBox="0 0 29.535 29.535"
                    class="course-content__list-icon"
                  >
                    <path
                      id="check-circle"
                      d="M20.784,12.027l-6.335,6.35L12.012,15.94A1.477,1.477,0,1,0,9.93,18.023l3.47,3.485a1.48,1.48,0,0,0,2.082,0l7.384-7.384a1.478,1.478,0,1,0-2.082-2.1ZM16.767,2A14.767,14.767,0,1,0,31.535,16.767,14.767,14.767,0,0,0,16.767,2Zm0,26.581A11.814,11.814,0,1,1,28.581,16.767,11.814,11.814,0,0,1,16.767,28.581Z"
                      transform="translate(-2 -2)"
                      fill="#fc8c9d"
                    />
                  </svg>
                  <p class="course-content__list-text">
                    {{ item }}
                  </p>
                </li>
              </ul>
            </section>

            <section class="course-content__section">
              <base-heading class="course-content__section-heading">
                {{ $t("course.needs") }}
              </base-heading>

              <p
                class="course-content__section-no-data"
                v-if="needs.length === 0"
              >
                {{ $t("no data") }}
              </p>

              <ul class="course-content__list" v-else>
                <li
                  class="course-content__list-item"
                  v-for="item in needs"
                  :key="item"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="29.535"
                    height="29.535"
                    viewBox="0 0 29.535 29.535"
                    class="course-content__list-icon  course-content__list-icon--requirements"
                  >
                    <path
                      id="check-circle"
                      d="M20.784,12.027l-6.335,6.35L12.012,15.94A1.477,1.477,0,1,0,9.93,18.023l3.47,3.485a1.48,1.48,0,0,0,2.082,0l7.384-7.384a1.478,1.478,0,1,0-2.082-2.1ZM16.767,2A14.767,14.767,0,1,0,31.535,16.767,14.767,14.767,0,0,0,16.767,2Zm0,26.581A11.814,11.814,0,1,1,28.581,16.767,11.814,11.814,0,0,1,16.767,28.581Z"
                      transform="translate(-2 -2)"
                      fill="#fc8c9d"
                    />
                  </svg>
                  <p class="course-content__list-text">
                    {{ item }}
                  </p>
                </li>
              </ul>
            </section>

            <section class="course-content__section">
              <base-heading class="course-content__section-heading">
                {{ $t("course.program") }}
              </base-heading>

              <p
                class="course-content__section-no-data"
                v-if="courseProgram.length === 0"
              >
                {{ $t("no data") }}
              </p>

              <base-accordion
                v-model="lessonStatus"
                class="course-content__accordion"
                v-else
              >
                <base-accordion-item
                  class="course-content__accordion-item"
                  v-for="(lesson, index) in courseProgram"
                  :key="lesson.name"
                  :name="lesson.name"
                  :label="`${index + 1}.`"
                >
                  <lesson-tile
                    :lesson="lesson"
                    :is-locked="!lesson.isFree"
                    @setLesson="selectLesson(lesson)"
                  />
                </base-accordion-item>
              </base-accordion>
            </section>

            <section class="course-content__section">
              <author-details :author="course.author" :course="course" />
            </section>
          </div>
        </div>
      </div>
      <div class="course__inner-right">
        <course-info :course="course" :isDemo="isDemo" />
      </div>
    </div>
  </div>
</template>

<script>
import course from '@/api/services/course';
import video from '@/api/upload/video';
import { isStringEmpty, getImageLink } from '@/helpers/';
import { mapGetters } from 'vuex';

export default {
  name: 'CoursePage',
  metaInfo() {
    return {
      title: `Edunails - ${this.course.name} course`,
      titleTemplate: null,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.course.description,
        },
      ],
    };
  },
  data: () => ({
    lessonStatus: '',
    activeLesson: null,
    activeSources: [],
    activeCaptions: [],
    course: false,
    videoLoading: false,
    videoShow: false,
    hasFoundFree: false,
    descriptionTest: undefined,
    freeLesson: {}
  }),
  watch: {
    activeLesson() {
      if (this.activeLesson)
        if (this.activeLesson.id !== this.freeLesson.id) {
          this.getVideoSources();
          this.getCaptions();
        }
        else {
          this.getPreviewVideo();
          if (this.isLogged)
            this.getCaptions();
        }
    },
    $route() {
      this.fetchCourse();
    },
  },
  methods: {
    selectLesson(lesson) {
      this.activeLesson = lesson;
      this.scrollTo();
    },
    scrollTo() {
      const target = document.querySelector('body');

      if (target) {
        target.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    },
    setFreeLesson() {
      this.course.lessons.forEach((lesson) => {
        if (!this.hasFoundFree && lesson.isFree) {
          this.freeLesson = lesson;
          this.hasFoundFree = true;
        }
      });

      if (this.freeLesson) {
        this.activeLesson = this.freeLesson;
        this.lessonStatus = [this.activeLesson.chapterTitle];
      } else {
        this.videoLoading = true;
      }
    },
    async getVideoSources() {
      this.activeSources = [];

      try {
        const { data } = await video.getAllVideoSources({
          courseID: this.activeLesson.courseId,
          lessonID: this.activeLesson.id,
        });

        Object.keys(data).forEach((key) => {
          if (key !== 'Id') {
            const qualityKey = key.substring(5);

            this.activeSources.push({
              src: data[key],
              type: 'video/mp4',
              label: key.substring(5),
              quality: parseInt(qualityKey.substring(0, qualityKey.length - 1)),
            });
          }

          // Sort based on quality field => from top to bottom
          this.activeSources.sort((a, b) => {
            return a.quality < b.quality ? 1 : -1;
          });
        });
      } catch (err) {
        console.log(err);
      }
    },
    async getCaptions() {
      this.videoLoading = false;

      try {
        const { data } = await video.getVideoSubtitles({
          courseId: this.activeLesson.courseId,
          lessonId: this.activeLesson.id,
        });

        this.activeCaptions = data;
        this.videoLoading = true;
      } catch (err) {
        this.captionError = true;
      }
    },
    async getPreviewVideo() {
      this.activeSources = [];
      const { data } = await video.getPreview({ courseId: this.course.id });

      this.activeSources.push({
        src: data.link,
        type: 'video/mp4',
        label: '1080p',
        quality: 1080,
      });
      this.videoLoading = true;
    },
    async fetchCourse() {
      this.$store.dispatch('ui/setPreloader', true);
      const { slug } = this.$route.params;

      try {
        const { data } = await course.getSingleCourse(slug);
        this.course = data;
        this.setFreeLesson();
      } catch (err) {
        this.$store.dispatch('notification/spawn', {
          errorMessage: err.response.data.message,
          type: 'error',
        });

        this.$router.push('/404');
      } finally {
        this.$store.dispatch('ui/setPreloader', false);
      }
    },
    async checkIsBought() {
      try {
        const { data } = await course.getCourseOrder(this.course.id);
        return data.paymentStatus === 'paid';
      } catch (error) {
        return false;
      }
    },
  },
  computed: {
    ...mapGetters({
      isLogged: 'auth/isLogged',
    }),
    willLearn() {
      return isStringEmpty(this.course.willLearn)
        ? []
        : this.course.willLearn.split(',');
    },
    isDemo() {
      return this.course.description.includes('DEMO - NOT FOR SALE');
    },
    needs() {
      return isStringEmpty(this.course.needs)
        ? []
        : this.course.needs.split(',');
    },
    imageLink() {
      return getImageLink(this.course.imageId);
    },
    courseProgram() {
      const { lessons } = this.course;

      return lessons.sort((lessonA, lessonB) => {
        return lessonA.sequenceNumber > lessonB.sequenceNumber ? 1 : -1;
      });
    },
    courseDescription() {
      return this.course ? this.course.description : undefined;
    },
  },
  async created() {
    await this.fetchCourse();
    this.$analytics.fbq.event('ViewContent');
    if (!this.isLogged) {
      return;
    }

    const isBought = await this.checkIsBought();
    if (isBought) {
      this.$router.push(`/watch-course/${this.course.id}`);
    }
  },
};
</script>

<style lang="scss" src="./Course.scss" />
